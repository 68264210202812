
import { Vue, Component } from 'vue-property-decorator'
import { ProjectDeviceList, DeviceInfo } from '@/types/ecologyMonitor'
import { MowRobotDetail, CuttimeList, Location } from '@/types/maintainJobMonitor'
import { PolygonPathDetail } from '@/types/common'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'

@Component
export default class SoilMonitor extends Vue {
  // 折叠面板当前展开项
  private activeName = 0
  // 当前地图展示项目
  private projectId = ''
  // 项目列表
  private projectDeviceList: ProjectDeviceList[] = []
  // 当前项目设备列表
  private deviceList = []
  private projectLocation: Array<PolygonPathDetail> = []
  private drawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []

  private onlineImg = require('@/assets/icon/sumec/normal.svg')
  private onlineImgCursor = require('@/assets/icon/sumec/normal-big.svg')
  private offImg = require('@/assets/icon/sumec/offline.svg')
  private offImgCursor = require('@/assets/icon/sumec/offline-big.svg')
  private warningImg = require('@/assets/icon/sumec/warning.svg')
  private warningImgCursor = require('@/assets/icon/sumec/warning-big.svg')
  private baseImg = require('@/assets/icon/sumec/base.svg')

  private drawerData: MowRobotDetail | null = null
  private taskInfoData: CuttimeList[] = []
  private cuttp = ''
  private cuttpNum = ''

  get electricityProgressColor () {
    let color = ''
    if (this.drawerData && (this.drawerData.quantityElectricity || this.drawerData.quantityElectricity === '0')) {
      color = Number(this.drawerData.quantityElectricity) < 20
        ? '#FF603B' : Number(this.drawerData.quantityElectricity) < 80
          ? '#FFC117' : '#13C2C2'
    }
    return color
  }

  created () {
    this.getData()
  }

  destroyed () {
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }

  // 左侧项目列表
  getData () {
    this.$axios.get(this.$apis.maintainJobMonitor.selectProjectMowingByList).then(res => {
      this.projectDeviceList = res || []
      if (this.projectDeviceList.length > 0) {
        this.projectId = this.projectDeviceList[0].projectId
        this.getPloygon()
      } else {
        this.$nextTick(() => {
          this.initMap(116.4017, 39.9)
        })
      }
    })
  }

  // 切换项目
  changeProject () {
    const id = this.projectDeviceList[Number(this.activeName)].projectId
    if ((this.activeName || this.activeName === 0) && this.projectId !== id) {
      this.projectId = id
      this.getPloygon()
      this.drawerClose()
    }
  }

  // 获取项目围栏
  getPloygon () {
    this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
      projectId: this.projectId
    }).then((res) => {
      if (res) {
        this.projectLocation = res.locationList || []
        const lng = Number(res.longitude)
        const lat = Number(res.latitude)
        this.$nextTick(() => {
          this.initMap(lng, lat)
        })
      } else {
        this.$message.error('未获取到项目信息')
      }
    })
  }

  // 获取割草区域
  getMowPloygon (deviceNumber: string) {
    let list: Array<Location> = []
    if (deviceNumber) {
      this.$axios.get(this.$apis.project.selectYhLocationByList, {
        objectId: deviceNumber
      }).then((res) => {
        list = res || []
        // 画预设割草面积
        if (list && list.length > 0) {
          const areaList: Array<{ longitude: number; latitude: number; fenceCode: string }> = list.map((areaItem: Location) => {
            return {
              fenceCode: areaItem.fenceCode || '',
              longitude: Number(areaItem.longitude),
              latitude: Number(areaItem.latitude)
            }
          })
          drawPolygon((this.map as AMap.Map), areaList, {
            fillColor: 'rgb(69, 182, 172)', // 背景色
            fillOpacity: 0.7, // 背景透明
            strokeWeight: 2, // 线条宽度
            strokeColor: 'rgba(69, 182, 172, 1)', // 线条色
            strokeStyle: 'dashed', // 线条轮廓线样式
            clickable: false // 是否可点击
          })
        }
      })
    }
  }

  getDeviceList () {
    this.$axios.get(this.$apis.maintainJobMonitor.selectMowingInfoByPage, {
      projectId: this.projectId
    }).then(res => {
      this.deviceList = res.list || []
      this.drawMarker(this.deviceList)
    })
  }

  // 初始化地图
  initMap (lng: number, lat: number) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [lng, lat]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, this.projectLocation || [], null, this.drawerClose)
      this.getDeviceList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 地图打点
  drawMarker (list: Array<any>) {
    this.markers = []
    if (list && list.length) {
      // 循坏将设备marker添加到地图
      list.forEach((item: MowRobotDetail) => {
        this.getMowPloygon(item.deviceNumber)
        // 基站添加
        if (item.chargeLongitude && item.chargeLatitude) {
          const markerBase: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: this.baseImg
            }),
            offset: new AMap.Pixel(-19, -18),
            position: new AMap.LngLat(Number(item.chargeLongitude), Number(item.chargeLatitude)),
            extData: item
          })
          markerBase.detail = item
          markerBase.on('click', this.drawerOpen)
          this.map && this.map.add(markerBase)
        }
        // 割草机器人添加
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(item.deviceStatus, item.faultInformation, false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude)),
            extData: { ...item, type: 'icon' }
          })
          marker.detail = item
          marker.on('click', this.drawerOpen)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 切换设备
  changeDevice (row: DeviceInfo) {
    if (this.map) {
      const e: any = {
        target: {}
      }
      if (row.longitude && row.latitude) {
        e.target = this.markers.find((item: any) => {
          return item.detail.deviceNumber === row.deviceNumber
        })
        this.map.setCenter([Number(row.longitude), Number(row.latitude)])
      } else {
        this.iconTab()
        e.target = {
          detail: row
        }
      }
      this.drawerOpen(e)
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    const info = e.target.getExtData()
    if (this.drawerData === null || (this.drawerData && this.drawerData.deviceNumber !== info.deviceNumber)) {
      if (this.drawerData && this.drawerData.deviceNumber !== info.deviceNumber) {
        this.drawerClose()
      }
      this.drawerData = info
      // 获取设备详情数据
      this.getDeviceDetail()
      // 点击切换大图标
      const iconClick = this.getIcon(info.deviceStatus, info.faultInformation, true)
      // 获取对应的割草机器人
      if (info.type !== 'icon') {
        const index = (this.map as AMap.Map).getAllOverlays('marker').findIndex((item: any) => item.getExtData() && item.getExtData().deviceNumber === info.deviceNumber && item.getExtData().type === 'icon')
        if (index >= 0) {
          const marker = (this.map as AMap.Map).getAllOverlays('marker')[index] as AMap.Marker
          marker.setOffset(new AMap.Pixel(-22, -64))
          marker.setIcon(iconClick)
        }
      } else {
        e.target.setOffset(new AMap.Pixel(-22, -64))
        e.target.setIcon(iconClick)
      }
    }
    this.drawer = true
  }

  // 处理机器人详情数据
  getDeviceDetail () {
    const weekData = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
    if (this.drawerData) {
      this.taskInfoData = this.drawerData.cuttimeList
      this.cuttp = this.drawerData.cuttp
      this.cuttpNum = this.drawerData.cuttpNum
      for (let i = this.taskInfoData.length - 1; i >= 0; i--) {
        this.taskInfoData[i].week = weekData[i]
        if (this.taskInfoData[i].time2Start === '00:00' && this.taskInfoData[i].time2End === '00:00') {
          this.taskInfoData[i].time2Start = ''
          this.taskInfoData[i].time2End = ''
        }
        if (this.taskInfoData[i].isWork === '2') {
          this.taskInfoData.splice(i, 1)
        }
      }
    }
  }

  // 获取图片
  getIcon (deviceStatus: string, faultInformation: string, isCurrent: boolean) {
    if (!isCurrent) {
      return (deviceStatus === '1' ? (faultInformation === '1' ? this.warningImg : this.onlineImg) : this.offImg)
    } else {
      return (deviceStatus === '1' ? (faultInformation === '1' ? this.warningImgCursor : this.onlineImgCursor) : this.offImgCursor)
    }
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 清空抽屉
      this.iconTab()
      this.drawerData = null
    }
  }

  // 替换图标
  iconTab () {
    if (this.map && this.drawerData) {
      const deviceNumber = this.drawerData.deviceNumber || ''
      const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceNumber === deviceNumber)
      const icon = this.getIcon(this.drawerData.deviceStatus, this.drawerData.faultInformation, false)
      const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
      if (this.drawerData.longitude && this.drawerData.latitude) {
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }
    }
  }

  onDetail (deviceNumber: string, state: string) {
    // 设备详情
    this.$router.push({
      path: '/maintainJobMonitor/mowRobot/deviceMowRobotDetail?projectId=' + this.projectId + '&deviceNumber=' + deviceNumber + '&active=' + state
    })
  }
}
